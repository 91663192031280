import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/layouts/LayoutDocs.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ButtonsNavigation = makeShortcode("ButtonsNavigation");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Introduction`}</h3>
    <p>{`Our charts are embedded with the use of an `}<a parentName="p" {...{
        "href": "https://www.w3schools.com/tags/tag_iframe.asp"
      }}>{`iFrame`}</a>{`. iFrame is short for Inline Frame.`}</p>
    <p>{`The iFrame element allows you to include (embed) content from a different source then your own website. You have probably seen YouTube videos embedded on websites other than YouTube. This is done with an iFrame.`}</p>
    <h4>{`Limitations of the basic iFrame element`}</h4>
    <p>{`The basic iFrame html element has limitations. These include: `}</p>
    <ul>
      <li parentName="ul">{`Not being able to properly size the iFrame to the dimensions of its content`}</li>
      <li parentName="ul">{`Not being able to able to communicate between the parent page and the iFrame`}</li>
      <li parentName="ul">{`Performance issues`}</li>
      <li parentName="ul">{`Security issues`}</li>
    </ul>
    <hr></hr>
    <h3>{`Providing a `}<em parentName="h3">{`better`}</em>{` experience`}</h3>
    <h4>{`iFrame Resizer library`}</h4>
    <p>{`We use the excellent `}<a parentName="p" {...{
        "href": "https://github.com/davidjbradshaw/iframe-resizer"
      }}>{`iframe-resizer`}</a>{` library by David J. Bradshaw (buy him a `}<a parentName="p" {...{
        "href": "https://www.buymeacoffee.com/davidjbradshaw"
      }}>{`coffee`}</a>{`!) to embed our charts. This library provides us with multiple tools to overcome the limitations of the basic iFrame element.`}</p>
    <p>{`The library is available for `}<a parentName="p" {...{
        "href": "https://github.com/davidjbradshaw/iframe-resizer"
      }}>{`jQuery`}</a>{`, which most of our clients use (e.g. building on Wordpress or a proprietary CMS). For more advanced websites there are ways to use `}<a parentName="p" {...{
        "href": "https://github.com/davidjbradshaw/iframe-resizer-react"
      }}>{`React`}</a>{`, `}<a parentName="p" {...{
        "href": "https://github.com/davidjbradshaw/iframe-resizer/blob/master/docs/use_with/vue.md"
      }}>{`Vue`}</a>{` or `}<a parentName="p" {...{
        "href": "https://github.com/davidjbradshaw/iframe-resizer/issues/478#issuecomment-347958630"
      }}>{`Angular`}</a>{`.`}</p>
    <h4>{`Why iframe-resizer?`}</h4>
    <p>{`Some of the tools iframe-resizer provides are:`}</p>
    <ul>
      <li parentName="ul">{`Height and width resizing of the iFrame to content size`}</li>
      <li parentName="ul">{`Detects changes to the DOM that can cause the page to resize using MutationObserver.`}</li>
      <li parentName="ul">{`Simplified messaging between iFrame and host page via postMessage.`}</li>
      <li parentName="ul">{`Fixes in page links in iFrame and supports links between the iFrame and parent page.`}</li>
      <li parentName="ul">{`Provides custom sizing and scrolling methods.`}</li>
      <li parentName="ul">{`Domain authentication for cross domain iFrames.`}</li>
    </ul>
    <hr></hr>
    <ButtonsNavigation linkNext="Getting started" linkNextTo="/docs/embedding-charts/getting-started" mdxType="ButtonsNavigation" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      